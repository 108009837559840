import * as React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'

const IndexPage = () => {
  return (
    <Layout pageTitle="michael raymond lund" desc="Visiting Michael Raymond Lunds home page">
      <Hero/>
    </Layout>
  )
}

export default IndexPage