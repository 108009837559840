import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "purple gesture.jpg"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  return (
      <HeroContainer>
        <HeroBg>
          <ImgBg image={data.file.childImageSharp.gatsbyImageData} alt="">
          </ImgBg>
        </HeroBg>
      </HeroContainer>
  )
}

export default Hero

const HeroContainer = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 1 rem;
    position: relative;
    margin-top: -80px;
`
const HeroBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left:0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const ImgBg = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  /* -o-object-fit: cover; */
  /* object-fit: cover; */
`
